export const itemType = {
  'Health Upgrade': [
    "Heart Container (MM)",
    "Heart Container (OoT)",
    "Heart Piece (MM)",
    "Heart Container",
    "Heart Piece",
    "Piece of Heart (OoT)",
    "Piece of Heart",
  ],
  'Large Rupee': [
    "Gold Rupee (MM)",
    "Gold Rupee (OoT)",
    "Purple Rupee (MM)",
    "Purple Rupee (OoT)",
    "Silver Rupee (MM)",
    "Gold Rupee",
    "Purple Rupee",
    "Silver Rupee",
  ],
  'Junk': [
    "1 Bombchu (MM)",
    "10 Arrows (OoT)",
    "10 Bombchu (MM)",
    "10 Bombchu (OoT)",
    "10 Bombs (OoT)",
    "10 Deku Nuts",
    "20 Bombchu (OoT)",
    "20 Bombs (OoT)",
    "30 Arrows (MM)",
    "30 Arrows (OoT)",
    "5 Arrows (OoT)",
    "5 Bombchu (MM)",
    "5 Bombchu (OoT)",
    "5 Bombs (OoT)",
    "Blue Rupee (MM)",
    "Blue Rupee (OoT)",
    "Gold Rupee (MM)",
    "Gold Rupee (OoT)",
    "Green Rupee (OoT)",
    "Heart Container (MM)",
    "Heart Container (OoT)",
    "Heart Piece (MM)",
    "Ice Trap (OoT)",
    "Piece of Heart (OoT)",
    "Purple Rupee (MM)",
    "Purple Rupee (OoT)",
    "Recovery Heart (OoT)",
    "Red Rupee (MM)",
    "Red Rupee (OoT)",
    "Silver Rupee (MM)",
    "10 Arrows",
    "10 Bombs",
    "20 Bombs",
    "30 Arrows",
    "5 Arrows",
    "5 Bombs",
    "Blue Rupee",
    "Gold Rupee",
    "Heart Container",
    "Heart Piece",
    "MM_MILK",
    "OOT_MILK",
    "Purple Rupee",
    "Recovery Heart",
    "Red Rupee",
    "SHARED_NUTS_10",
    "Silver Rupee"
  ]
}