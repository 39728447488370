import djcarmichael from '../img/djcarmichael.jpeg'
import ghunzor from '../img/ghunzor.png'

export const twitchStreamers = [
  {
    name: 'DJCarmichael',
    icon: djcarmichael,
    link: 'https://www.twitch.tv/djcarmichael',
    channel: 'djcarmichael'
  },
  {
    name: 'Ghunzor',
    icon: ghunzor,
    link: 'https://www.twitch.tv/ghunzor',
    channel: 'ghunzor'
  }
]

export const otherResources = [
  
]